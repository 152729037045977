import { rewards } from "@fscrypto/domain";
import { Button, Card, CardContent, Text } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { TrophyIcon } from "lucide-react";
import { tracking } from "~/utils/tracking";

interface Props {
  programs: rewards.Program[];
}

const excludedPrograms = ["Ambassador Payments", "Ambassador-Payments"];

export const Programs = ({ programs }: Props) => {
  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
      {programs
        .filter((p) => !excludedPrograms.includes(p.programConfig.name))
        .map((program) => (
          <ProgramCard key={program.id} program={program} />
        ))}
    </div>
  );
};

const ProgramCard = ({ program }: { program: rewards.Program }) => {
  const splitName = program.programConfig.name.split(" ");
  const nameWithoutDate = splitName.slice(0, 2).join(" ");
  const date = splitName.slice(2).join(" ");
  const variant = nameWithoutDate.includes("Gold")
    ? "gold"
    : nameWithoutDate.includes("Silver")
      ? "silver"
      : nameWithoutDate.includes("Bronze")
        ? "bronze"
        : "primary";
  const variantClass =
    variant === "gold"
      ? "bg-[#FFCB45] text-[#FFCB45]"
      : variant === "silver"
        ? "bg-[#EBEBEB] text-[#EBEBEB]"
        : variant === "bronze"
          ? "bg-[#C25D00] text-[#C25D00]"
          : "bg-[#FFCB45]";

  return (
    <Card key={program.id} className="shadow">
      <CardContent className="h-full">
        <div className="flex h-full flex-col justify-between space-y-8">
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-row items-center justify-between">
              <div className={`flex items-center justify-center rounded-full p-2 ${variantClass}`}>
                <TrophyIcon className="h-5 w-5 text-white dark:text-black" />
              </div>
              <Text className="text-gray-40 dark:text-gray-30 self-start" size="sm">
                {date}
              </Text>
            </div>
            <Text size="2xl" weight="medium" className="text-contrast">
              {nameWithoutDate}
            </Text>
            <Text size="sm" className="pr-4">
              {program.programConfig.jsonTags.cardDescription || program.programConfig.programDescription}
            </Text>
          </div>
          <Button className="w-min px-6" size="sm" variant="outline" asChild>
            <Link
              to={`/rewards/${program.id}`}
              onClick={() => tracking(`earn_leaderboard_click_${program.id}`, "Earn")}
            >
              {"View Leaderboard"}
            </Link>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
