import { DashboardCard, EnhancedDashboard } from "~/features/discover/components/dashboard-card";
import GridContainer from "~/features/discover/components/grid-container";
import { getBonkDashboard } from "~/features/discover/dashboards-container";

interface HomeDashboardsProps {
  dashboards: EnhancedDashboard[];
  setProject?: (v: string) => void;
  isInsights?: boolean;
}
export const HomeDashboards = ({ dashboards, setProject, isInsights }: HomeDashboardsProps) => {
  return (
    <div className="-mx-6">
      <GridContainer>
        {isInsights && getBonkDashboard()}
        {dashboards.slice(0, 4).map((dashboard, i) => {
          return <DashboardCard key={i} dashboard={dashboard} onSelectProject={setProject} showUser />;
        })}
      </GridContainer>
    </div>
  );
};
