import { Hero } from "./home-hero/hero";
import { HomeSection } from "./home-section";
import { HomeDashboards } from "./home-dashboards/home-dashboards";
import { TopAnalysts } from "./home-top-analysts/top-analysts";
import { RewardsService, type rewards, type tag } from "@fscrypto/domain";
import Footer from "../app-shell/footer/footer";
import { Container } from "@fscrypto/ui";
import { ProjectTag } from "./home-hero/project-tag";
import { DashboardSearchData } from "~/services/discover-service";
import { TopAnalyst } from "@fscrypto/domain/user";
import { Link } from "@remix-run/react";
import { $path } from "remix-routes";
import { GrailBanner } from "~/features/grail/banner";
import { Programs } from "../earn/components/programs";
import { useMemo } from "react";

interface HomeContainerProps {
  topAnalysts: TopAnalyst[];
  trendingDashboardData: DashboardSearchData;
  insightsDashboardData: DashboardSearchData;
  featuredProjects: tag.Tag[];
  rewardPrograms: rewards.Program[];
}
export const HomeContainer = ({
  topAnalysts,
  trendingDashboardData,
  insightsDashboardData,
  featuredProjects,
  rewardPrograms,
}: HomeContainerProps) => {
  const sortedPrograms = useMemo(() => RewardsService.sortPrograms(rewardPrograms), [rewardPrograms]);
  return (
    <div>
      <GrailBanner />
      <Hero rewardProgram={sortedPrograms[0]} />
      <div className="border-stroke flex flex-wrap justify-center gap-3 border-b px-8 py-4">
        {featuredProjects.map((project) => {
          return (
            <Link key={project.id} to={$path("/insights/dashboards/:project", { project: project.name })}>
              <ProjectTag key={project.id} tag={project} onClick={() => null} />
            </Link>
          );
        })}
      </div>
      <Container className="flex flex-col gap-y-14 py-14">
        <HomeSection
          title="Verified Insights"
          moreLink={{ to: $path("/insights/verified-insights"), text: "Discover more" }}
        >
          <HomeDashboards dashboards={insightsDashboardData.items} isInsights={true} />
        </HomeSection>
        <HomeSection
          title="Trending Dashboards"
          moreLink={{ to: $path("/insights/dashboards"), text: "Discover more" }}
        >
          <HomeDashboards dashboards={trendingDashboardData.items} />
        </HomeSection>
        {sortedPrograms.length > 0 && (
          <HomeSection title="Build Dashboards to Earn">
            <Programs programs={sortedPrograms} />
          </HomeSection>
        )}
        <HomeSection title="Top Analysts" moreLink={{ to: $path("/insights/analysts"), text: "All analysts" }}>
          <TopAnalysts topAnalysts={topAnalysts} />
        </HomeSection>
      </Container>
      <Footer />
    </div>
  );
};
